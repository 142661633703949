import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// endpoints
import { LOGIN_API } from '../../config/endpoints';
import { BASE_URL } from '../../config-global';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (data) => ({
        url: LOGIN_API,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useRegisterUserMutation } = authApi;
