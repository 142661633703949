import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// endpoints
import {
  DEL_TEMPLATE_BY_POST_ID_API,
  GET_CREATE_ORDER_API,
  GET_DASHBOARD_DATA_API,
  GET_IMAGES_API,
  GET_ORDER_LIST_API,
  GET_POST_LIST_API,
  GET_PRICING_PLANS_API,
  GET_TEMPLATE_BY_POST_ID_API,
  GET_TEMPLATE_LIST_API,
  POST_MY_ACCOUNT_API,
  POST_TEMPLATE_BY_POST_ID_API,
  SAVE_TEMPLATE_BY_POST_ID_API,
} from '../../config/endpoints';
import { BASE_URL } from '../../config-global';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDashBoard: builder.query({
      query: (data) => ({
        url: GET_DASHBOARD_DATA_API,
        method: 'GET',
      }),
      transformResponse: (res) => res?.dashboard,
    }),
    getPosts: builder.query({
      query: (params) => ({
        url: GET_POST_LIST_API,
        method: 'GET',
        params,
      }),
    }),
    getProductImages: builder.mutation({
      query: (body) => ({
        url: GET_IMAGES_API,
        method: 'POST',
        body,
      }),
    }),
    saveTemplate: builder.mutation({
      query: (body) => ({
        url: SAVE_TEMPLATE_BY_POST_ID_API,
        method: 'POST',
        body,
      }),
    }),
    getTemplateById: builder.query({
      query: ({ postId }) => ({
        url: `${GET_TEMPLATE_BY_POST_ID_API}${postId}`,
        method: 'GET',
      }),
    }),
    addTemplate: builder.mutation({
      query: (body) => ({
        url: POST_TEMPLATE_BY_POST_ID_API,
        method: 'POST',
        body,
      }),
    }),
    deleteAutomation: builder.mutation({
      query: ({ postId }) => ({
        url: `${DEL_TEMPLATE_BY_POST_ID_API}${postId}`,
        method: 'DELETE',
      }),
    }),
    getAutomations: builder.query({
      query: (params) => ({
        url: GET_TEMPLATE_LIST_API,
        method: 'GET',
        params,
      }),
    }),
    getOrders: builder.query({
      query: (params) => ({
        url: GET_ORDER_LIST_API,
        method: 'GET',
        params,
      }),
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: POST_MY_ACCOUNT_API,
        method: 'POST',
        body,
      }),
    }),
    getPlans: builder.query({
      query: (params) => ({
        url: GET_PRICING_PLANS_API,
        method: 'GET',
        params,
      }),
    }),
    createOrder: builder.query({
      query: (params) => ({
        url: GET_CREATE_ORDER_API,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetDashBoardQuery,
  useLazyGetPostsQuery,
  useGetProductImagesMutation,
  useSaveTemplateMutation,
  useLazyGetTemplateByIdQuery,
  useAddTemplateMutation,
  useLazyGetAutomationsQuery,
  useGetOrdersQuery,
  useUpdateProfileMutation,
  useGetPlansQuery,
  useLazyCreateOrderQuery,
  useDeleteAutomationMutation,
} = userApi;
