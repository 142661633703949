import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// slices
import userReducer from './slices/user';

// API Slices
import { authApi, userApi } from './apiSlices';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['user'], // persist slice,
};
const rootReducer = combineReducers({
  user: userReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

export { rootPersistConfig, rootReducer };
