import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Stack, BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { PATH_DASHBOARD } from '../../routes/paths';
import Iconify from '../../components/iconify';
import Header from './header/Header';

// ----------------------------------------------------------------------

const FOOTER_NAVIGATION_ROUTES = [
  PATH_DASHBOARD.app,
  PATH_DASHBOARD.automations,
  PATH_DASHBOARD.account,
];
const HEADER_NAVIGATION_ROUTES = [PATH_DASHBOARD.app];

export default function AppLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(location.pathname);

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleNavigationChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const showFooterNavigation = FOOTER_NAVIGATION_ROUTES.includes(location.pathname);
  const showHeaderNavigation = HEADER_NAVIGATION_ROUTES.includes(location.pathname);

  return (
    <>
      {showHeaderNavigation && (
        <Paper
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            maxWidth: 430,
            margin: 'auto',
            width: '100%',
            zIndex: 100,
            borderRadius: 0,
          }}
          elevation={3}
        >
          <Header />
        </Paper>
      )}
      <Stack
        sx={{
          margin: 'auto',
          maxWidth: 430,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Outlet />
      </Stack>
      {showFooterNavigation && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            maxWidth: 430,
            margin: 'auto',
            width: '100%',
            zIndex: 100,
            borderRadius: '16px',
          }}
          elevation={3}
        >
          <BottomNavigation
            value={value}
            onChange={handleNavigationChange}
            showLabels
            sx={{
              width: '100%',
              borderRadius: '16px 16px 0 0',
              height: '4rem',
            }}
          >
            <BottomNavigationAction
              value={PATH_DASHBOARD.app}
              label="Home"
              icon={<Iconify icon="mingcute:home-3-fill" width={20} />}
              showLabel={false}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  fontSize: 12,
                },
              }}
            />

            <BottomNavigationAction
              value={PATH_DASHBOARD.selectPost}
              label="Auto DM"
              icon={<Iconify icon="fluent:bot-add-48-regular" width={20} />}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  fontSize: 12,
                },
              }}
            />
            <BottomNavigationAction
              value={PATH_DASHBOARD.automations}
              label="Automations"
              icon={<Iconify icon="mi:list" width={20} />}
              showLabel={false}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  fontSize: 12,
                },
              }}
            />
            <BottomNavigationAction
              value={PATH_DASHBOARD.account}
              label="Profile"
              icon={<Iconify icon="icon-park-outline:user" width={20} />}
              showLabel={false}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  fontSize: 12,
                },
              }}
            />
          </BottomNavigation>
        </Paper>
      )}
    </>
  );
}
