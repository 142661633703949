import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loggedIn: false,
  language: null,
  user: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }) {
      return {
        ...state,
        ...payload,
        loggedIn: true,
      };
    },

    logout(state) {
      return {
        ...initialState,
        language: state.language,
      };
    },
  },
});

// Reducer (for configureStore)
export default slice.reducer;

// Actions (for dispatch)
export const { setUser, logout } = slice.actions;
