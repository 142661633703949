import { logout } from '../slices/user';

const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    const { payload } = action;
    if (payload?.status === 401) {
      // perform logout operation
      dispatch(logout());
    } else if (payload?.status === 500) {
      window.location.href = '/500';
    }

    return next(action);
  };

export default apiMiddleware;
