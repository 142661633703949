import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const AboutPage = Loadable(lazy(() => import('../pages/AboutPage')));
export const Contact = Loadable(lazy(() => import('../pages/ContactPage')));
export const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
export const TnCPage = Loadable(lazy(() => import('../pages/TnCPage')));

// APP
export const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashboardPage')));
export const SelectPostPage = Loadable(lazy(() => import('../pages/select-post/SelectPostPage')));
export const AddProductPage = Loadable(lazy(() => import('../pages/add-product/AddProductPage')));
export const PreviewAutomationPage = Loadable(
  lazy(() => import('../pages/preview-automation/PreviewAutomationPage'))
);
export const AutomationsPage = Loadable(lazy(() => import('../pages/automations/AutomationsPage')));
export const AccountPage = Loadable(lazy(() => import('../pages/account/AccountPage')));
export const PricingPage = Loadable(lazy(() => import('../pages/pricing/PricingPage')));
