import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Link, Box, Button } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
import AccountPopover from './AccountPopover';

import { PATH_DASHBOARD } from '../../../routes/paths';

import { useAuthContext } from '../../../auth/useAuthContext';
import { getPlanName } from '../../../utils/plan';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { user } = useAuthContext();
  const planName = getPlanName(user?.plan_name);
  const expiresInDays = user?.plan_expires_in_days;
  const { themeLayout } = useSettingsContext();

  const renderContent = (
    <>
      <Link href={PATH_DASHBOARD.pricing} underline="none" sx={{ ml: 1 }}>
        <Label color="info" startIcon={<Iconify icon="solar:dollar-bold" color="#FFB302" />}>
          {planName}
        </Label>
      </Link>
      <Box sx={{ flexGrow: 1 }} />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <Toolbar
      sx={{
        height: 1,
      }}
    >
      {renderContent}
    </Toolbar>
  );
}
