import isLocalhost from './functions';

const instaLogin = () => {
  const redirect_uri = isLocalhost()
    ? 'https://localhost:3030/auth/verify'
    : 'https://texthauler.com/auth/verify';

  const redirectUrl = `https://www.instagram.com/oauth/authorize?client_id=1026593999024124&redirect_uri=${redirect_uri}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments`;
  window.open(redirectUrl, '_self');
};

export default instaLogin;
