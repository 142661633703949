import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import CompactLayout from '../layouts/compact';
import AppLayout from '../layouts/app/AppLayout';

// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  VerifyCodePage,

  // MAIN
  Page500,
  Page403,
  Page404,
  HomePage,
  AboutPage,
  Contact,
  PrivacyPolicyPage,
  TnCPage,
  //
  DashboardPage,
  SelectPostPage,
  AddProductPage,
  PreviewAutomationPage,
  AutomationsPage,
  AccountPage,
  PricingPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            {
              path: 'verify',
              element: <VerifyCodePage />,
            },
          ],
        },
      ],
    },

    // App
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'select-post', element: <SelectPostPage /> },
        { path: 'add-product/:postId', element: <AddProductPage /> },
        { path: 'preview-automation/:postId', element: <PreviewAutomationPage /> },
        { path: 'automations', element: <AutomationsPage /> },
        { path: 'account', element: <AccountPage /> },
        { path: 'pricing', element: <PricingPage /> },
      ],
    },

    // Main Routes
    {
      element: (
        <GuestGuard>
          <MainLayout />
        </GuestGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <AboutPage /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'privacy', element: <PrivacyPolicyPage /> },
        { path: 'tos', element: <TnCPage /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
